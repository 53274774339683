<template>
  <h1 class="text-center">Modals</h1>
  <ul class="text-center">
    <li class="mt-20 text-3xl">NR. 1
      <ModalNr1/>
    </li>


  </ul>


</template>

<script>

import ModalNr1 from "../../components/partials/Modals/ModalNr1";

export default {
  name: "Modals",
  components: {ModalNr1}

}
</script>

<style scoped>

</style>